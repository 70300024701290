import { useEffect, useState, useContext, useRef } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  CircularProgress,
  TextareaAutosize,
  Box
} from '@material-ui/core';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import appContext from "../../context/appContext";

import { getToken } from '../../helpers/firebase';

import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css'
import { concat } from 'lodash';


/*
import ReactQuill from "react-quill"
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css'

var BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
var ColorStyle = ReactQuill.Quill.import('attributors/style/color');
var SizeStyle = ReactQuill.Quill.import('attributors/style/size');
var Alignment = ReactQuill.Quill.import('attributors/style/align');
ReactQuill.Quill.register(BackgroundStyle, true);
ReactQuill.Quill.register(ColorStyle, true);
ReactQuill.Quill.register(SizeStyle, true);
ReactQuill.Quill.register(Alignment, true);
ReactQuill.Quill.register("modules/htmlEditButton", htmlEditButton);

*/


const AgreementTemplateTagsResults = () => {
  const context = useContext(appContext);
  const [currentTag, setCurrentTag] = useState('');
  const tagHtml = useRef(null);
  let fields = [];
  const [editorStatus, setEditorStatus] = useState(true);


  /*
    const modules = {
      'htmlEditButton': {},
      toolbar: [
        [{ table: [] }],
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link"],
        ["clean"],
      ],
    };
  */

  let setPreloader = (value) => {
    context.dispatch({ type: "SET_LOADING_INNER", payload: value })
  }

  useEffect(async () => {
    getAgreementFields();
  }, [context.user]);



  let saveAgreementField = async () => {

    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    let response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/saveAgreementField`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        name: currentTag,
        value: tagHtml.current.value
      })
    });
    let resUpdate = await response.json();
    console.log('resUpdate', resUpdate);
    setPreloader(false);

    context.dispatch({ type: "GET_ALL_AGREEMENT_CONTENT", payload: resUpdate.data.content })

  }



  const getAgreementFields = async () => {
    setPreloader(true);

    let token = await getToken();
    console.log(token);
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getAgreementFieldsAndContent`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',

    });
    console.log(response);
    const resJson = await response.json();
    console.log(resJson.fields);
    console.log(resJson.content)
    context.dispatch({ type: "GET_ALL_AGREEMENT_FIELDS", payload: resJson.fields })
    context.dispatch({ type: "GET_ALL_AGREEMENT_CONTENT", payload: resJson.content })
    fields = resJson.fields;
    setPreloader(false);
    setEditorStatus(false)

  }

  let handleChange = (e) => {
    console.log(e.target.value)
    setCurrentTag(e.target.value);
    let currentTag = context.state.agreementFields.find((element) => element.name == e.target.value);
    console.log(currentTag)
    tagHtml.current.value = currentTag.html;
  }




  return (

    <Grid container spacing={2}>


      <Grid container >


        <Grid container xs={{ size: 12, display: 'block' }} mt={10}>

          <Grid item xs={4} >
            <FormControl fullWidth>
              <InputLabel id="agreement-tags">Agreement tags</InputLabel>
              <Select
                labelId="agreement-tags"
                value={currentTag}
                label="Alert"
                onChange={handleChange}
              >
                {
                  context.state.agreementFields.map((tag) => <MenuItem value={tag.name}>{tag.name}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>


          <Grid container mt={10}>

            <TextareaAutosize
              style={{ width: "100%", height: "400px" }}
              ref={tagHtml}
            />
          </Grid>
          <Grid container justifyContent="center">
            <Button variant="outlined" onClick={saveAgreementField}  >Save</Button>
          </Grid>
        </Grid>


      </Grid>
    </Grid>

  );
};


export default AgreementTemplateTagsResults;


