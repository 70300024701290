import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({

  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#302c2c'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  overrides: {
    MuiButton: {
      text: {
        color: "#f1f1f1",
      },
    },
  }
});

export default theme;
