import { useState } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, List } from '@material-ui/core';

const NavItem = ({
  href,
  icon: Icon,
  title,
  children,
  ...rest
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  return (
    <div onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}>
      <ListItem style={{ position: "relative" }}

        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
        {...rest}
      >
        <Button
          component={RouterLink}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main'
            }),
            '& svg': {
              mr: 1
            }
          }}
          to={href}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <span>
            {title}
          </span>
        </Button>


      </ListItem>
      {open && children && <List style={{ paddingLeft: "20px" }} >
        {children.map((child) => {
          return <Button
            component={RouterLink}
            sx={{
              fontSize: '12px',
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: 'primary.main'
              }),
              '& svg': {
                mr: 1
              }
            }}
            to={child.href}
          >

            <span>
              {child.title}
            </span>
          </Button>
        })}
      </List>}
    </div>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
