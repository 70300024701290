import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import appContext from "../../context/appContext";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Dropzone from 'react-dropzone'
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const UploadTeacherFiles = () => {
    const context = useContext(appContext);
    const [snackBarMessageShow, setSnackBarMessageShow] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [showLoader, setShowLoader] = useState('none');
    const [snackType, setSnackType] = useState('');
    useEffect(async () => {
        console.log(context.state.userList);
        getImages();
    }, [context.state.token]);



    const deleteImage = async (name) => {
        setShowLoader('block');
        const myHeaders = new Headers();

        myHeaders.append('Authorization', `Bearer ${context.state.token}`);
        console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
        const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/deleteFileFirebase`, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${context.state.token}`
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', //
            body: JSON.stringify({
                name,

            })
        });

        const resJson = await response.json();
        setShowLoader('none');

        if (resJson.status == "success") {

            getImages();
            setOpen(false);
            setSnackType('success');
            setSnackBarMessageShow(true);
            setToastMessage(resJson.msg)
        } else {
            setSnackType('error');
            setSnackBarMessageShow(true);
            setToastMessage(resJson.msg)
        }
    }

    const getImages = async () => {
        setShowLoader('block');
        const myHeaders = new Headers();

        myHeaders.append('Authorization', `Bearer ${context.state.token}`);
        console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
        const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getTeacherImages`, {
            method: 'POST',
            headers: myHeaders,
        });
        console.log(response);
        const resJson = await response.json();
        console.log(resJson.files);

        context.dispatch({ type: "GET_STAFF_IMAGES", payload: resJson.files })

        setShowLoader('none');
    }
    const handleToastClose = (event, reason) => {

        setSnackBarMessageShow(false);
    };

    const uploadFileToFirebae = async (file) => {
        setShowLoader('block');
        const formData = new FormData();
        let hasError = false;


        formData.append("file", file[0]);

        const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/uploadFileFirebase`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${context.state.token}`
            },

            body: formData
        });
        const resJson = await response.json();
        console.log(resJson);
        setShowLoader('none');
        if (resJson.status == "success") {
            getImages();
            setOpen(false);
            setSnackType('success');
            setSnackBarMessageShow(true);
            setToastMessage(resJson.msg)
        } else {
            setSnackType('error');
            setSnackBarMessageShow(true);
            setToastMessage(resJson.msg)
        }

    }

    return (
        <div>
            <Box component="span" display={showLoader}>
                <LinearProgress />
            </Box>
            <Snackbar open={snackBarMessageShow} onClose={handleToastClose} autoHideDuration={4000}>
                <Alert severity={snackType}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Dropzone onDrop={acceptedFiles => uploadFileToFirebae(acceptedFiles)} accept="image/jpeg, image/png" >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div style={{ height: "250px", margin: "30px auto", textAlign: "center", border: "2px solid black" }}>Drop file here</div>
                        </div>
                    </section>
                )}
            </Dropzone>
            <ImageList rowHeight={250} cols={7}>
                {context.state.staffImageList.map((image) => {
                    return (
                        <ImageListItem key={image.id}>
                            <img
                                src={image.src}
                                loading="lazy"
                                height="200px"

                            />
                            {image.url}
                            <ImageListItemBar
                                title={image.name.split("/")[1]}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${image.name}`}
                                        onClick={() => deleteImage(image.name)}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    );
                })
                }
            </ImageList >
        </div >

    );
}


export default UploadTeacherFiles;
