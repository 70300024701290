import { useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import appContext from "../context/appContext";
import { isAndroid } from 'react-device-detect';

import { signInWithEmailAndPassword } from '../helpers/firebase';


const Login = () => {

  const context = useContext(appContext);

  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  return (
    <>
      <Helmet>
        <title>Login | LEC</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Formik
          initialValues={{
            email: '',

            password: '',
          }}

          onSubmit={async (values, { setSubmitting }) => {

            signInWithEmailAndPassword(values.email, values.password);

            setSubmitting(false);

          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField

                label="Email"
                margin="normal"
                name="email"
                fullWidth

                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
              <TextField
                fullWidth

                label="Password"
                margin="normal"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </form>
          )}
        </Formik>
      </Box >

    </>
  );
};

export default Login;
