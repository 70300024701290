import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import LinkList from 'src/pages/LinksList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import UsersList from 'src/pages/UsersList';
import AppMessages from 'src/pages/AppMessages';
import AppEmailSms from 'src/pages/AppEmailSms';
import AgreementTemplate from 'src/pages/AgreementTemplate';
import AgreementTemplateTags from 'src/pages/AgreementTemplateTags';


import UploadTeacherFiles from './components/upload-teacher-files/upload_teacher_files';

const routes = (isLoggedIn) => [

  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'userslinks', element: <LinkList /> },
      { path: 'users', element: <UsersList /> },
      { path: 'appmessages', element: <AppMessages /> },
      { path: 'appemailsms', element: <AppEmailSms /> },
      { path: 'agreementtemplate', element: <AgreementTemplate /> },
      { path: 'agreementtemplatetags', element: <AgreementTemplateTags /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'upload-teacher-files', element: <UploadTeacherFiles /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
