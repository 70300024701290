import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import appContext from "../../context/appContext";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';
import { getToken } from '../../helpers/firebase';

export default function CreateLinkDialog() {
    const [open, setOpen] = useState(false);
    const [showLoader, setShowLoader] = useState('none');

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [errors, setErrors] = useState({ fullName: false, email: false });
    const context = useContext(appContext);
    const [snackBarMessageShow, setSnackBarMessageShow] = useState(false);
    const [toastMessage, setToastMessage] = useState('');


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addUser = async () => {
        try {
            setShowLoader('block');

            let hasError = false;
            if (fullName == "") {
                setErrors(prevState => ({ ...prevState, fullName: true }));
                hasError = true;
            } else {
                setErrors({ ...errors, fullName: false });
            }
            /*
            if (email == "") {
                setErrors(prevState => ({ ...prevState, email: true }));
                hasError = true;
            } else {
                setErrors({ ...errors, email: false });
            }
            */

            if (hasError) {
                setShowLoader('none');
                return;
            }

            let token = await getToken();
            const response = await fetch(`${window.location.hostname === "localhost" ? window.location.hostname === "localhost" ? process.env.REACT_APP_URL_DEBUG : process.env.REACT_APP_URL_DEBUG : window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/addDeepLinks`, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', //
                body: JSON.stringify({
                    fullName: fullName,
                    email: email,
                    phone: PhoneNumber,
                    token: token
                })
            });

            const resJson = await response.json();
            console.log(resJson);

            context.dispatch({ type: "GET_USERS_LINKS", payload: resJson.data })
            setOpen(false);
            setShowLoader('none');
            setSnackBarMessageShow(true);
            setToastMessage(resJson.msg)
        } catch (e) {
            console.log(e);
        }

    }

    const handleTextFieldChange = (e) => {
        if (e.target.id == "name") {
            setFullName(e.target.value);
        } else if (e.target.id == "phone")
            setPhoneNumber(e.target.value);
        else if (e.target.id == "email") {
            setEmail(e.target.value);
        }
    }

    const handleToastClose = (event, reason) => {

        setSnackBarMessageShow(false);
    };

    return (
        <span>
            <Snackbar open={snackBarMessageShow} onClose={handleToastClose} autoHideDuration={4000}>
                <Alert severity="info">
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add user
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">ADD USER</DialogTitle>
                <DialogContent>

                    <TextField
                        error={errors.fullName}
                        margin="dense"
                        id="name"
                        label="Full name"
                        type="text"
                        fullWidth
                        onChange={handleTextFieldChange}
                    />
                    <TextField
                        error={errors.email}
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        onChange={handleTextFieldChange}
                    />
                    <TextField
                        error={errors.phone}
                        margin="dense"
                        id="phone"
                        label="Phone number"
                        type="text"
                        fullWidth
                        onChange={handleTextFieldChange}
                    />
                    <Box component="span" display={showLoader}>
                        <LinearProgress />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addUser} color="primary">
                        Register
                    </Button>

                </DialogActions>
            </Dialog>
        </span>
    );
}
