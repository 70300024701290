import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Tooltip,
  Button,
  CircularProgress,
  IconButton,
  Chip,
  Snackbar,
  Alert,
  TableSortLabel,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import { getToken } from '../../helpers/firebase';
import NavItem from '../NavItem';
import CreateLinkDialog from './CreateLinkCsv';
import CreateLinkCsvDialog from './CreateLink';
import { Search as SearchIcon } from 'react-feather';
import { Eye as EyeIcon } from 'react-feather';
import { EyeOff as EyeOffIcon } from 'react-feather';

import { Info as InfoIcon } from 'react-feather';

import appContext from "../../context/appContext";
import { Trash } from 'react-feather';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';


var _ = require('lodash');

const LinksListResults = () => {
  const context = useContext(appContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [snackBarMessageShow, setSnackBarMessageShow] = useState(false);
  const [sortObj, setSortObj] = useState({ 'email': 'asc', 'fullName': 'asc', 'phone': 'asc' });
  const [toastMessage, setToastMessage] = useState('');
  const [emailSortStatus, setEmailSortStatus] = useState('asc');
  const [userListLinks, setUsersListLinks] = useState([]);
  const [isButtonPressedOne, setIsButtonPressedOne] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentInvitationInfo, setCurrentInvitationInfo] = useState('');
  const [openInvitationInfo, setOpenInvitationInfo] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  let setPreloader = (value) => {
    context.dispatch({ type: "SET_LOADING_INNER", payload: value })
  }
  const [columns, setColumns] = useState([
    {
      field: 'info', headerName: '', width: 50,
      renderCell: (params) => {
        return <InfoIcon onClick={(e) => { e.stopPropagation(); loadInfoAboutInvitation(params.row.id) }} />

      },
    },
    { field: 'fullName', headerName: 'Full Name', width: 200, },
    {
      field: 'email', headerName: 'Email', minWidth: 250,
    },
    {
      field: 'phone', headerName: 'Phone number', width: 200,
    },
    {
      field: 'emailsInfo', headerName: 'Emails info', type: 'boolean',
      valueFormatter: (params) => {
        if (params.value && params.value.length > 0) {
          return true
        }
        return false;
      },
    },
    {
      field: 'smsInfo', headerName: 'Sms info', type: 'boolean',
      renderCell: (params) => {
        if (params.value) {
          return (
            <ul>
              {
                params.value.map((smsInfo) => {
                  console.log(smsInfo);
                  return smsInfo['status'] == "delivered" ? <li>Not delivered </li> : <li>Delivered </li>
                })

              }
            </ul>
          )

        }
        return '';

      },

    },
    {
      field: 'used', headerName: 'Used', type: 'boolean'

    },
    {
      field: 'url', headerName: 'Invitations url',
      renderCell: (params) => {
        return !params.row.used ? <Button variant="contained" style={{ fontSize: '8px' }} color="error" onClick={(e) => {
          e.stopPropagation();
          resendInvite(params.id);
        }}>
          Resend invitation
        </Button> : <div></div>
      }
    },
    {
      field: 'createdAt',
      headerName: 'Invitations date',
      width: 200,
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value._seconds * 1000).format("MM/DD/YYYY")
        }
        return '';
      },
    },
    {
      field: 'updatedAt', headerName: 'Update date', width: 200,
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value._seconds * 1000).format("MM/DD/YYYY")
        }
        return '';
      },
    },
    {
      field: 'usedAt', headerName: 'Creation date', width: 200,
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value._seconds * 1000).format("MM/DD/YYYY")

        }
        return '';
      },
    },
    {
      field: 'userUid', headerName: 'User uid', width: 280,
      renderCell: (params) => {
        return <NavItem href={`/app/users?uid=${params.row.userUid}`} key={params.row.userUid} title={params.row.userUid} />
      },

    },

    {
      field: 'delete', headerName: '',
      renderCell: (params) => {
        return <Trash onClick={(e) => { e.stopPropagation(); deleteLink(params.row.id) }} />
      },
    },

  ]);



  const handleClickOpenInvite = () => {
    setOpenInvitationInfo(true);
  };

  const handleCloseInvite = () => {
    setOpenInvitationInfo(false);
  };




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [customers, setCustomers] = useState([]);


  useEffect(async () => {
    let userLinks = await getUsersLinks();
    setUsersListLinks(userLinks);
  }, [context.userList]);


  const searchUserList = (e) => {
    console.log(e.target.value);
    let links = context.state.userListLinks.filter(link => {
      if (!!link.email) {
        if (link.email.includes(e.target.value)) {
          return true;
        }

      }
      if (!!link.phone) {
        if (link.phone.includes(e.target.value)) {
          return true;
        }
      }
      if (!!link.fullName) {
        if (link.fullName.includes(e.target.value)) {
          return true;
        }
      }

    });
    setUsersListLinks(links);
    setPage(0);
  }

  const deleteLink = async (id) => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/deleteDeepLink`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        id: id
      })
    });
    console.log(response);
    const resJson = await response.json();
    setPreloader(false);
    setSnackBarMessageShow(true);
    setToastMessage(resJson.msg)
    console.log(resJson.data);
    if (resJson.status == 'success') {
      context.dispatch({ type: "GET_USERS_LINKS", payload: resJson.data })
    }
  }


  const resendAllSelected = async () => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/resendAllInviteIds`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        ids: selectedRows.map((row) => row.id)
      })
    });
    console.log(response);
    const resJson = await response.json();
    setPreloader(false);
    setSnackBarMessageShow(true);
    setToastMessage(resJson.msg)
    console.log(resJson.data);

  }

  const resendAllInvite = async (id) => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/resendAllOpenInvite`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({

      })
    });
    console.log(response);
    const resJson = await response.json();
    setPreloader(false);
    setSnackBarMessageShow(true);
    setToastMessage(resJson.msg)
    console.log(resJson.data);

  }

  const loadInfoAboutInvitation = async (id) => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getInvitationInfo`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        id: id
      })
    });
    const resJson = await response.json();
    setPreloader(false);

    setCurrentInvitationInfo(resJson.invite);
    setOpenInvitationInfo(true);

  }

  const resendInvite = async (id) => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/resendInvite`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        id: id
      })
    });
    console.log(response);
    const resJson = await response.json();
    setPreloader(false);
    setSnackBarMessageShow(true);
    setToastMessage(resJson.msg)
    console.log(resJson.data);

  }

  const getUsersLinks = async () => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getDeepLinks`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',

    });
    console.log(response);
    const resJson = await response.json();
    setPreloader(false);
    console.log(resJson.data);
    context.dispatch({ type: "GET_USERS_LINKS", payload: resJson.data })
    return resJson.data;
  }



  const handleLimitChange = (event) => {
    console.log(event.target.value);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleToastClose = (event, reason) => {

    setSnackBarMessageShow(false);
  };


  const _sortStatus = (status) => {
    let listSorted;
    if (status == null) {
      listSorted = context.state.userListLinks;
    } else {
      listSorted = _.sortBy(context.state.userListLinks, { 'used': status });

    }
    setUsersListLinks(listSorted);
    setPage(0);

  }

  const _handleSort = (field) => {

    sortObj[field] = sortObj[field] == "asc" ? "desc" : "asc";
    setSortObj(sortObj);
    let listSorted = _.sortBy(context.state.userListLinks, field);
    if (sortObj[field] == 'desc') {
      listSorted = listSorted.reverse();
    }
    console.log(listSorted[0])
    setUsersListLinks(listSorted);
    setPage(0);
  }



  return (
    <Card>

      <Snackbar open={snackBarMessageShow} onClose={handleToastClose} autoHideDuration={4000}>
        <Alert severity="info">
          {toastMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openInvitationInfo}
        onClose={handleCloseInvite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Invitation info"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <pre>{
              JSON.stringify(currentInvitationInfo, null, 2)
            }</pre>
          </DialogContentText>
        </DialogContent>

      </Dialog>
      <PerfectScrollbar>

        <Box sx={{ minWidth: 1050 }}>

          <Box sx={{ mt: 3 }}>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">

                  Are you sure you want to send all open invitations?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="error">No</Button>
                <Button onClick={() => {
                  handleClose();
                  resendAllInvite();
                }} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Card>
              <CardContent>

                <Box sx={{ maxWidth: 500 }}>
                  {selectedRows.length > 0 ? <Button onClick={
                    resendAllSelected
                  } variant="contained" color="info" justify="flex-end">Resend slected</Button> : <div></div>}
                  <CreateLinkCsvDialog />
                  <CreateLinkDialog />

                </Box>

              </CardContent>
            </Card>
          </Box>

          <div style={{ height: 600, width: '100%' }}>

            <DataGridPro
              components={{ Toolbar: GridToolbar }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = userListLinks.filter((row) =>
                  selectedIDs.has(row.id),
                );

                setSelectedRows(selectedRows);

              }}
              rows={userListLinks}
              columns={columns}
              rowsPerPageOptions={[50, 100, 150, 1500]}
              pagination
              checkboxSelection

            />
          </div>

        </Box>
      </PerfectScrollbar>


    </Card >
  );
};


export default LinksListResults;
