import { useEffect, useState, useContext, useRef } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import appContext from "../../context/appContext";

import { getToken } from '../../helpers/firebase';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

var BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
var ColorStyle = ReactQuill.Quill.import('attributors/style/color');
var SizeStyle = ReactQuill.Quill.import('attributors/style/size');
var Alignment = ReactQuill.Quill.import('attributors/style/align');
ReactQuill.Quill.register(BackgroundStyle, true);
ReactQuill.Quill.register(ColorStyle, true);
ReactQuill.Quill.register(SizeStyle, true);
ReactQuill.Quill.register(Alignment, true);

const AppEmailSmsResults = () => {
  const context = useContext(appContext);
  const [currentAlert, setCurrentAlert] = useState('');
  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [display, setDisplay] = useState('none')

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }

  useEffect(async () => {
    getEmailSms();
  }, [context.user]);



  const getEmailSms = async () => {
    let token = await getToken();
    console.log(token);
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getAllSmsEmail`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',

    });
    console.log(response);
    const resJson = await response.json();
    console.log(resJson.data);
    context.dispatch({ type: "GET_ALL_EMAIL_SMS", payload: resJson.data })
  }

  let handleChange = (e) => {
    setCurrentAlert(e.target.value);
    setDisplay(true);
    let currentMessage = context.state.emailSmsApp.find((element) => element.id == e.target.value);
    setContent(currentMessage.content);
    setTitle(currentMessage.title);
  }
  let setPreloader = (value) => {
    context.dispatch({ type: "SET_LOADING_INNER", payload: value })
  }
  let updateEmailSms = async () => {

    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    let response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/updateSmsEmail`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        id: currentAlert,
        title: title,
        content: content
      })
    });
    let resUpdate = await response.json();
    console.log();
    setPreloader(false);
    setMessage(resUpdate.msg);
    context.dispatch({ type: "GET_ALL_EMAIL_SMS", payload: resUpdate.data })

  }


  return (
    <Grid container spacing={2}>

      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="app-messages">App Email and Sms</InputLabel>
          <Select
            labelId="app-messages"
            value={currentAlert}
            label="Alert"
            onChange={handleChange}
          >
            {
              context.state.emailSmsApp.map((message) => <MenuItem value={message.id}>{message.description}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid container sx={{ display: display }}>
        <ReactQuill
          theme='snow'
          value={content}
          onChange={setContent}
          style={{ minHeight: '300px', width: '100%' }}
          modules={modules}

        />

        <Grid container xs={{ size: 12, display: 'block' }} mt={10}>

          <Grid container justifyContent="center">
            <Button variant="outlined" onClick={updateEmailSms}  >Save</Button>
          </Grid>
          {message != "" && message}
        </Grid>
      </Grid>
    </Grid>
  );
};


export default AppEmailSmsResults;
