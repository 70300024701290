import { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';

import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Button,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon, TableSortLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import { useSearchParams } from "react-router-dom";

import { Search as SearchIcon, Info as InfoIcon, Send, UserPlus } from 'react-feather';

import appContext from "../../context/appContext";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';


import { getToken, getCurrentUserUid } from '../../helpers/firebase';
var _ = require('lodash');

const UserListResults = () => {
  const context = useContext(appContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [sortObj, setSortObj] = useState({ 'email': 'asc', 'name': 'asc', 'phone': 'asc', 'familyIdentifier': 'asc' });
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [openUserPush, setOpenUserPush] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useSearchParams();

  const [columns, setColumns] = useState([

    {
      field: 'uid', headerName: '#', width: 250,
    },
    {
      field: 'name', headerName: 'Full Name', width: 200,
    },
    {
      field: 'familyIdentifier', headerName: 'Family identifier', width: 200,
      renderCell: (params) => {
        if (params.row.name != null) {
          return <div>{params.row.familyIdentifier}</div>
        }

        return <div>No family was found on lec</div>
      },
    },
    {
      field: 'email', headerName: 'Email', width: 200,
    },
    {
      field: 'phone', headerName: 'Phone number', width: 200,
    },
    {
      field: 'pretend', headerName: '',
      renderCell: (params) => {
        return <div>

          <Button variant="contained" size="small" color="success" style={{ fontSize: 10 }} onClick={() => { getUserToken(params.row.uid, true) }}>DEV</Button>
          <br />
          <Button variant="contained" size="small" color="error" style={{ fontSize: 10 }} onClick={() => { getUserToken(params.row.uid, false) }}>LIVE</Button>
        </div>

      },


    },

    {
      field: 'info', headerName: '',
      renderCell: (params) => {
        return <InfoIcon onClick={() => { loadInfoAboutUser(params.row.uid, context) }} />

      },


    },
    {
      field: 'push', headerName: '',
      renderCell: (params) => {
        return <Send onClick={() => { setOpenUserPush(true); }} />

      },


    },
  ]);

  const handleClickOpenUserInfo = () => {
    setOpenUserInfo(true);
  };

  const handleCloseUserInfo = () => {
    setOpenUserInfo(false);
  };

  const handleClickOpenUserPush = () => {
    setOpenUserPush(true);
  };

  const handleCloseUserPush = () => {
    setOpenUserPush(false);
  };

  useEffect(async () => {
    console.log(context.state.users);
    let usersFirst = await getUsers();
    setUsers(usersFirst);
  }, [context.user]);


  const searchUser = (e) => {
    console.log(e.target.value);
    let users = context.state.users.filter(user => {
      if (!!user.uid) {
        if (user.uid.includes(e.target.value)) {
          return true;
        }
      }
      if (!!user.email) {
        if (user.email.includes(e.target.value)) {
          return true;
        }
      }
      if (!!user.phone) {
        if (user.phone.includes(e.target.value)) {
          return true;
        }
      }
      if (!!user.name) {
        if (user.name.includes(e.target.value)) {
          return true;
        }
      }

    });
    setPage(0);
    setUsers(users);

  }



  const _handleSort = (field) => {

    sortObj[field] = sortObj[field] == "asc" ? "desc" : "asc";
    setSortObj(sortObj);
    let users = _.sortBy(context.state.users, field);
    if (sortObj[field] == 'desc') {
      users = users.reverse();
    }
    console.log(users[0])
    setUsers(users);
    setPage(0);
  }

  const logOutUser = async (id) => {
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/logOutUser`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        uid: id
      })
    });
    console.log(response);

  }

  const getUsers = async () => {
    console.log(search.get('uid'))
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getAllUsers`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        uid: search.get('uid')
      })
    });
    console.log(response);
    const resJson = await response.json();
    setPreloader(false);

    console.log(resJson.data);
    context.dispatch({ type: "GET_USERS", payload: resJson.data })
    return resJson.data;
  }

  let setPreloader = (value) => {
    context.dispatch({ type: "SET_LOADING_INNER", payload: value })
  }

  const handleLimitChange = (event) => {
    console.log(event.target.value);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const loadInfoAboutUser = async (id, context) => {
    setPreloader(true);
    let token = await getToken();
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getUserInfo`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        uid: id
      })
    });
    const resJson = await response.json();
    setPreloader(false);
    console.log('resJson', resJson.user);

    setCurrentUserInfo(resJson.user);
    setOpenUserInfo(true);
  }

  const getUserToken = async (uid, isDev) => {
    setPreloader(true);

    let currentUserUid = await getCurrentUserUid();
    let token = await getToken();

    const responseUid = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/loginUserGetToken`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        uid: uid,
        currentUserUid: currentUserUid
      })
    });
    const resJsonUid = await responseUid.json();

    if (window.location.hostname == "localhost") {
      window.open(`http://localhost:6003?loginToken=${resJsonUid.loginToken}`, '_blank');

    } else {
      if (isDev) {
        window.open(`https://dev-lecfl.web.app?loginToken=${resJsonUid.loginToken}`, '_blank');

      } else {
        window.open(`https://app.lecfl.com?loginToken=${resJsonUid.loginToken}`, '_blank');

      }
    }

    setPreloader(false);
  }


  return (
    <Card>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Dialog
          open={openUserPush}
          onClose={handleCloseUserPush}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Send notifications"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && errors.email}
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && errors.password}
                    <button type="submit" disabled={isSubmitting}>
                      Submit
                    </button>
                  </form>
                )}
              </Formik>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Dialog
          open={openUserInfo}
          onClose={handleCloseUserInfo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"User info"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <pre>{
                JSON.stringify(currentUserInfo, null, 2)
              }</pre>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </Box>

      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>

          <div style={{ height: 600, width: '100%' }}>

            <DataGridPro
              components={{ Toolbar: GridToolbar }}
              rows={users}
              columns={columns}
              rowsPerPageOptions={[50, 100, 150, 1500]}
              searchParams
              pagination
            />
          </div>


        </Box>
      </PerfectScrollbar>


    </Card>
  );
};


export default UserListResults;
