import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import React, { useEffect, useReducer, useState } from "react";
import { ThemeProvider, CircularProgress, Grid } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
// context import
import appContext from "./context/appContext";
// reducers import
import { initialState, appReducer } from "./reducers/appReducer";
import firebase, { isAdmin, signOut } from './helpers/firebase';
import { LicenseInfo } from '@mui/x-data-grid-pro';


const App = () => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  let routing = useRoutes(routes(state.user));

  let setUserProvider = async (user) => {

    if (user.email == "liranhecht@gmail.com" || user.email == "sholem@lecfl.com" || user.email == "app@lecfl.com" || user.email == "rrudd@lecfl.com" && user.email == "batya@lecfl.com") {

      dispatch({ type: "SET_USER", payload: user })
    } else {
      signOut();
    }
  }


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {

      dispatch({ type: "APP_LOADING_STATE", payload: false });
      if (user == null) {
        dispatch({ type: "SET_USER", payload: user })
      } else {
        console.log(user);
        setUserProvider(user);
      }



    })
    LicenseInfo.setLicenseKey(
      process.env.REACT_APP_DATA_GRID_KEY,
    )
    //getToken();
  }, []);


  return (


    !state.appLoading ?
      < appContext.Provider value={{ state, dispatch }
      }>

        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </appContext.Provider > : <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >

        <Grid item xs={3}>
          <CircularProgress color="secondary" size={100} />

        </Grid>
      </Grid>
  );

};

export default App;
