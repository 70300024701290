

import { signOut } from '../helpers/firebase';

export const initialState = {
    isLoggedIn: localStorage.getItem('userToken') ? true : false,
    token: localStorage.getItem('userToken') ? localStorage.getItem('userToken') : '',
    userListLinks: [],
    staffImageList: [],
    user: {},
    users: [],
    messages: [],
    emailSmsApp: [],
    agreementFields: [],
    agreementContent: '',
    appLoading: true,
    preloader: false,
}


export const appReducer = (state, action) => {
    switch (action.type) {
        case "SET_LOADING_INNER":
            return {
                ...state,
                preloader: action.payload
            };
        case "GET_USERS_LINKS":
            return {
                ...state,
                userListLinks: action.payload
            };
        case "APP_LOADING_STATE":
            return {
                ...state,
                appLoading: action.payload
            };
        case "GET_USERS":
            return {
                ...state,
                users: action.payload
            };
        case "GET_ALL_MESSAGES":
            return {
                ...state,
                messages: action.payload
            };
        case "GET_ALL_EMAIL_SMS":
            return {
                ...state,
                emailSmsApp: action.payload
            };
        case "GET_ALL_AGREEMENT_FIELDS":
            return {
                ...state,
                agreementFields: action.payload,

            };
        case "GET_ALL_AGREEMENT_CONTENT":
            return {
                ...state,
                agreementContent: action.payload
            };

        case "GET_STAFF_IMAGES":
            return {
                ...state,
                staffImageList: action.payload
            };
        case "SET_TOKEN_AND_LOGIN":
            return {
                ...state,
                token: action.payload,
                isLoggedIn: true
            };
        case "SET_USER":
            return {
                ...state,
                user: action.payload,
            };

        case "REVOKE_TOKEN_AND_LOGOUT":
            localStorage.removeItem('userToken');
            return {
                ...state,
                token: '',
                isLoggedIn: false
            };
        default:
            return state;
    }
};